<template lang="pug">
div
  template(v-for='refinementGroup in refinementGroups' :key='refinementGroup.title')
    AlgoliaRefinementList(
      :title='refinementGroup.title'
      :values='refinementGroup.values'
      :is-light='isLight'
      :is-collapsed-default='isCollapsedDefault'
    )
      template(#default='{ display, link }')
        universal-link(:link='link' class='text-dark') {{ display }}
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'
const { $algolia, $sitewideConfig } = useNuxtApp()
const route = useRoute()
const router = useRouter()

const {
  level,
  isLight = false,
  isCollapsedDefault = false,
  catalogData,
} = defineProps<{
  level: number
  isLight?: boolean
  isCollapsedDefault?: boolean
  catalogData: any
}>()

const refinementGroups = computed(() => {
  const refinementNames = $algolia.searchContexts.catalog.state.value.refinements[`categories.lvl${level}.text`] || []
  const refinementSlugs = $algolia.searchContexts.catalog.state.value.refinements[`categories.lvl${level}.slug`] || []
  const isCategoryPage = route.name?.toString().includes('category')
  const isBrandPage = route.name?.toString().includes('brand')
  const isInStockSameDayActive = $algolia.searchContexts.catalog.isRefined('sameDayShipping')

  const refinements = refinementNames.map((refinement, index) => {
    const slug = refinementSlugs[index].value.split('|')

    const routeObj: RouteLocationRaw = {
      name: 'category',
      params: slug.reduce((acc, lvl, lvlIndex) => {
        acc[`lvl${lvlIndex}`] = lvl
        return acc
      }, {}),
      query: {},
    }

    // On Brand pages make sure we add the brand query filter to the route obj
    if (isBrandPage) routeObj.query.brand = catalogData.title // LAME

    // If ISSD is active make sure we add the sameDayShipping query filter to the route obj
    if (isInStockSameDayActive) routeObj.query.sameDayShipping = route.query.sameDayShipping

    return {
      ...refinement,
      slug,
      link: router.resolve(routeObj).fullPath, // TODOLATER: Ditch resolve and use /c/ etc..
    }
  })

  if (route.name !== 'mmy' || !$sitewideConfig.config.useSecondLevel) {
    // If we are on a category page we need to make sure we only show child categories of the current parent
    // Ex: If a product is in 2 root categories it would display both children in the list and thats not ideal.
    const values = isCategoryPage
      ? refinements.filter((refinement) => refinement.slug.join('/').indexOf(catalogData.slug) === 0)
      : refinements

    return [
      {
        title: 'Category',
        values,
      },
    ]
  }

  const rootCategories = refinements.reduce((acc, refinement) => {
    const [group] = refinement.slug
    const [title] = refinement.value.split('|')

    if (!acc[group]) acc[group] = { title: '', values: [] }

    acc[group].title = title
    acc[group].values.push(refinement)

    return acc
  }, {})

  return Object.keys(rootCategories)
    .sort()
    .map((key) => {
      return rootCategories[key]
    })
})
</script>
