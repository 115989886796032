<template lang="pug">
ul(class='refinement-list bg-white text-sm lg:overflow-x-hidden lg:overflow-y-auto')
  li(
    v-for='refinement in refinements'
    :key='refinement.display'
    class='m-0 h-14 block pl-10 text-sm text-black border-b-2 border-gray-lighter'
  )
    div
      label(
        class='before:w-5 before:h-5 before:rounded-[3px] before:border-2 before:border-solid before:mr-2.5 before:flex-[0_0_auto] flex h-14 cursor-pointer items-center'
        :class='refinement.isRefined ? isRefinedClass : "before:border-gray-dark"'
      )
        input(type='checkbox' :checked='refinement.isRefined' class='hidden' @change='toggleFacet(refinement)')
        div {{ refinement.display }}
</template>

<script setup lang="ts">
const { $algolia } = useNuxtApp()
const { data } = defineProps<{ data: any }>()
const route = useRoute()
const refinements = computed(() => {
  const refinements = $algolia.searchContexts.catalog.state.value.refinements[data.facet] || []
  return refinements.filter(({ isValid }) => isValid)
})
function toggleFacet(refinement) {
  const { value, isRefined } = refinement

  if (isRefined) $algolia.searchContexts.catalog.removeRefinements(data.facet, value)
  else $algolia.searchContexts.catalog.addRefinements(data.facet, value)

  const { mod } = route.query

  const newRoute: any = {
    name: route.name,
    params: {
      ...route.params,
    },
    query: {
      ...(mod && { mod }),
      ...$algolia.searchContexts.catalog.createRouteQuery(),
    },
  }

  navigateTo(newRoute)
}

const isRefinedClass = computed(() => {
  return "before:bg-action before:border-action before:bg-[url('/images/checkmarkThick.svg')] before:bg-[length:12px] before:bg-no-repeat before:bg-center"
})
</script>
