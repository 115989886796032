<template lang="pug">
div
  template(v-if='$device.value.isSmall')
    Overlay(unique-key='facetMenu' :enable-dark-mode='true' :is-persistent='true')
      template(#header)
        div(class='flex items-center')
          div(class='title') Filter
          div(class='font-normal text-sm pl-1')
            | (#[AlgoliaStats(class='inline')])
      CatalogRefinementsSameDayShippingFacet(v-if='catalogData.showSameDayShippingFacet' :is-light='true')
      CatalogRefinementsCategory(
        v-if='!catalogData.category.showAsFacet'
        :level='catalogData.category.facetLevel'
        :is-light='true'
        :is-collapsed-default='true'
        :catalog-data='catalogData'
      )
      template(v-for='{ facet, text } in catalogData.facets' :key='text')
        AlgoliaRefinementList(
          :attribute='facet'
          :title='text'
          :is-light='true'
          :is-collapsed-default='true'
          :show-hightlight-when-refined='true'
          :show-selected-refinements-when-collapsed='true'
        )
      template(#footer)
        div(class='flex')
          BaseButton(color='GRAY' class='w-full mr-3' @click='clearRefinements') Clear All
          BaseButton(class='w-full ml-3' @click='$overlay.close()') Done

  template(v-else)
    CatalogRefinementsSameDayShippingFacet(v-if='catalogData.showSameDayShippingFacet')
    CatalogRefinementsCategory(
      v-if='!catalogData.category.showAsFacet'
      :level='catalogData.category.facetLevel'
      :catalog-data='props.catalogData'
    )

    template(v-for='{ facet, text } in catalogData.facets' :key='text')
      AlgoliaRefinementList(:attribute='facet' :title='text' @refinement-clicked='trackRefinement')
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'
const { $uiEvents, $overlay, $algolia } = useNuxtApp()
const route = useRoute()
const props = defineProps<{
  catalogData: any
}>()

function clearRefinements() {
  $algolia.searchContexts.catalog.clearRefinements()

  // Right now we remove mod from the url manually here. if we wanted to make sure we don't
  // close the menu when we clear we would need to make sure we leave it in the query string.
  const newRoute: RouteLocationRaw = {
    name: route.name?.toString() || '',
    params: {
      ...route.params,
    },
    query: {
      ...$algolia.searchContexts.catalog.createRouteQuery(),
    },
  }

  navigateTo(newRoute)
}

function trackRefinement(data: any) {
  const { title, value } = data

  $uiEvents.$emit('facetClicked', {
    facet: title.toLowerCase(),
    value: value.toLowerCase(),
    route,
  })
}
</script>
