<template lang="pug">
div(
  v-if='resultCount > 0 || isActive'
  class='rounded mb-3 border border-solid border-gray-lighter overflow-hidden'
  :class='[isDisabled ? "opacity-25 cursor-default" : "cursor-pointer"]'
)
  div(class='py-2 px-3 font-bold' :class='headerClasses' @click='toggleFacet')
    div(class='header-title uppercase flex justify-between items-center')
      | {{ sameDayShippingText }} Only

      div(class='flex-none ml-2 my-2 h-4 w-8 flex items-center rounded-full border-2 border-transparent' :class='toggleColors')
        span(class='h-3 w-3 bg-white rounded-full transform shadow' :class='[!isActive ? "translate-x-0" : "translate-x-4"]')

  div(v-show='!isActive && !isDisabled' class='pt-1 px-4 pb-2 bg-white text-sm lg:pt-2')
    p Only display items that ship the quickest
</template>

<script setup lang="ts">
const emit = defineEmits(['refinementClicked']) // LAME
const { isActive, isDisabled, toggleColors, toggleFacet } = useSameDayFacetHelper(emit)
const { sameDayShippingText, sameDayShippingBackgroundColor, sameDayShippingBorderColor } = useSameDayShippingDisplay()
const { $algolia } = useNuxtApp()

const { isLight = false } = defineProps<{ isLight?: Boolean }>()

const resultCount = computed(() => {
  return $algolia.searchContexts.catalog.state.value.resultCount
})

const headerClasses = computed(() => {
  const classes = []

  if (isActive.value) {
    classes.push('text-white border-transparent', sameDayShippingBackgroundColor)
  } else {
    classes.push(
      'text-dark border-t-2 border-solid',
      isLight ? 'bg-white' : 'bg-gray-lighter',
      isDisabled.value ? 'border-transparent' : sameDayShippingBorderColor
    )
  }

  return classes
})
</script>
