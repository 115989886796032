<template lang="pug">
div(
  v-if='refinements.length > 0'
  class='mb-3 rounded border border-solid cursor-pointer overflow-hidden'
  :class='showHightlightWhenRefined && selectedRefinements ? "border-info" : "border-gray-lighter"'
)
  div(class='py-2 px-4 text-dark font-bold' :class='isLight ? "bg-white" : "bg-gray-lighter"' @click='isCollapsed = !isCollapsed')
    div(
      class='after:content-["-"] after:block after:text-[20px] uppercase flex justify-between items-center'
      :class='isCollapsed && collapsedClass'
    ) {{ title }}
    div(
      v-if='showSelectedRefinementsWhenCollapsed && isCollapsed && selectedRefinements'
      class='font-normal text-sm text-gray transform-none truncate'
    ) {{ selectedRefinements }}

  ul(
    v-show='!isCollapsed'
    class='lg:max-h-[500px] pt-1 px-4 pb-2 bg-white text-sm lg:pt-2 lg:overflow-x-hidden lg:overflow-y-auto'
  )
    template(v-for='refinement in refinements' :key='refinement.value')
      li(class='lg:mb-[15px] mb-[18px] last:mb-0')
        slot(v-bind='refinement')
          div(:class='refinement.isRefined && refinedClass')
            label(
              class='flex items-center before:w-[20px] before:h-[20px] before:rounded-[3px] before:border-2 before:border-solid before:border-gray-dark before:mr-[10px] before:flex-grow-0 before:flex-shrink-0 before:flex-auto'
            )
              input(type='checkbox' :checked='refinement.isRefined' class='hidden' @change='toggleFacet(refinement)')
              slot(name='display' :display='refinement.display')
                | {{ refinement.display }}
</template>

<script>
import SearchComponent from './Component'

export default defineNuxtComponent({
  extends: SearchComponent,
  props: {
    attribute: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
      default: () => null,
    },
    isLight: {
      type: Boolean,
      default: false,
    },
    showHightlightWhenRefined: {
      type: Boolean,
      default: false,
    },
    isCollapsedDefault: {
      type: Boolean,
      default: false,
    },
    showSelectedRefinementsWhenCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCollapsed: false,
    }
  },
  computed: {
    refinements() {
      if (this.values) return this.values

      const refinements = this.$searchContext.state.value.refinements[this.attribute] || []

      return refinements.filter(({ isValid }) => isValid)
    },
    selectedRefinements() {
      return this.refinements
        .filter(({ isRefined }) => isRefined)
        .map(({ display }) => display)
        .join(', ')
    },
    collapsedClass() {
      return "after:content-['+']"
    },
    refinedClass() {
      return "before:[&>label]:bg-action before:[&>label]:border-action before:[&>label]:bg-[url('/images/checkmarkThick.svg')] before:[&>label]:bg-[size:12px] before:[&>label]:bg-no-repeat before:[&>label]:bg-center"
    },
  },
  created() {
    this.isCollapsed = this.isCollapsedDefault
  },
  setup() {
    return {
      navigateTo,
    }
  },
  methods: {
    toggleFacet(refinement) {
      const { value, isRefined } = refinement

      if (isRefined) this.$searchContext.removeRefinements(this.attribute, value)
      else this.$searchContext.addRefinements(this.attribute, value)

      const { mod } = this.$route.query

      const routeObj = {
        name: this.$route.name,
        params: {
          ...this.$route.params,
        },
        query: {
          ...(mod && { mod }),
          ...this.$searchContext.createRouteQuery(),
        },
      }

      this.$emit('refinementClicked', {
        attribute: this.attribute,
        title: this.title,
        value,
      })

      this.navigateTo(routeObj)
    },
  },
})
</script>
