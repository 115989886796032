<template lang="pug">
div(v-if='activeRefinementCount > 0' class='flex flex-wrap mb-5')
  template(v-for='refinement in displayRefinements' :key='refinement.value')
    div(
      class='flex items-center overflow-hidden text-sm mb-1 mr-2 cursor-pointer'
      @click='clearRefinement(refinement.attributeText, refinement.attribute, refinement.value)'
    )
      div(class='py-1 px-2 flex items-center bg-white border border-solid border-gray-light rounded')
        template(v-if='refinement.attribute === "sameDayShipping"')
          | {{ sameDayShippingText }} Only

        template(v-else)
          | {{ refinement.display }}
        CloseIcon(class='ml-2 w-2 h-2 fill-current text-gray')

  div(
    v-if='activeRefinementCount > displayLimit'
    class='flex items-center overflow-hidden border border-solid border-gray-light rounded text-sm mb-1 mr-2 cursor-pointer'
    @click='showAllRefinements = !showAllRefinements'
  )
    div(class='py-1 px-2 flex items-center bg-white')
      template(v-if='showAllRefinements') Show Less
      template(v-else) + {{ activeRefinementCount - displayLimit }}

  div(
    class='flex items-center overflow-hidden border border-solid border-gray-light rounded text-sm mb-1 mr-2 cursor-pointer'
    @click='clearRefinements'
  )
    div(class='py-1 px-2 flex items-center bg-white') Clear All
</template>

<script setup lang="ts">
import CloseIcon from '@/assets/x.svg?component'
const { $algolia, $uiEvents } = useNuxtApp()
const { sameDayShippingText } = useSameDayShippingDisplay()
const { catalogData } = defineProps<{ catalogData: any }>()
const route = useRoute()
const displayLimit = ref(4)
const showAllRefinements = ref(false)

const activeRefinementCount = computed(() => {
  return $algolia.searchContexts.catalog.state.value.activeRefinementCount
})

const activeRefinements = computed(() => {
  return $algolia.searchContexts.catalog.state.value.activeRefinements
})

const refinementGroupNames = computed(() => {
  return catalogData.facets.reduce((acc, { facet, text }) => {
    acc[facet] = text
    return acc
  }, {})
})

const displayRefinements = computed(() => {
  return Object.entries(activeRefinements.value)
    .reduce((acc, [attribute, values]) => {
      // Format the values of the attribute
      const formattedAttributeValues = formatAttributeValues(attribute, values)

      // Force the sameDayShipping attribute to always be at the front
      if (attribute === 'sameDayShipping') acc.unshift(...formattedAttributeValues)
      else acc.push(...formattedAttributeValues)

      return acc
    }, [])
    .slice(0, showAllRefinements.value ? undefined : displayLimit.value)
})

function formatAttributeValues(attribute, values) {
  return values.map(({ value, display }) => ({
    attributeText: refinementGroupNames.value[attribute] || attribute,
    attribute,
    display,
    value,
  }))
}

function clearRefinement(title, attribute, refinement) {
  $uiEvents.$emit('facetClicked', {
    facet: title.toLowerCase(),
    value: refinement.toLowerCase(),
    route,
  })

  $algolia.searchContexts.catalog.removeRefinements(attribute, refinement)

  const newRoute: any = {
    name: route.name,
    params: {
      ...route.params,
      //noUpdateScroll: true,
    },
    query: {
      ...$algolia.searchContexts.catalog.createRouteQuery(),
    },
  }

  navigateTo(newRoute)
}

function clearRefinements() {
  $algolia.searchContexts.catalog.clearRefinements()

  const newRoute: any = {
    name: route.name,
    params: {
      ...route.params,
      //noUpdateScroll: true,
    },
    query: {
      ...$algolia.searchContexts.catalog.createRouteQuery(),
    },
  }

  navigateTo(newRoute)
}
</script>
