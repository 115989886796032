<template lang="pug">
div(class='whitespace-nowrap font-bold text-dark')
  span(class='hidden lg:inline') {{ resultStart }} - {{ resultEnd }} of&nbsp;
  | {{ resultCount }}
  template(v-if='resultCount >= 1000') +
  | &nbsp;{{ resultLabel }}
</template>

<script>
import SearchComponent from './Component'

export default defineNuxtComponent({
  extends: SearchComponent,
  computed: {
    results() {
      return this.$searchContext.state.value.results
    },
    resultCount() {
      if (this.$searchContext.state.value.resultCount > 1000) return 1000
      return this.$searchContext.state.value.resultCount
    },
    resultLabel() {
      return this.resultCount === 1 ? 'result' : 'results'
    },
    resultStart() {
      return (
        (this.$searchContext.state.value.currentPage - 1) *
          this.$searchContext.state.value.searchParameters.resultsPerPage +
        1
      )
    },
    resultEnd() {
      return this.resultStart + this.$searchContext.state.value.results.length - 1
    },
  },
})
</script>
