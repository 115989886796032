<template lang="pug">
div(v-if='resultCount > 0 || isActive' class='overflow-hidden' :class='[isDisabled ? "cursor-default" : "cursor-pointer"]')
  div(class='pl-10 pr-3 font-bold py-3' :class='[headerClasses, isDisabled ? "opacity-25" : ""]' @click='toggleFacet')
    div(class='flex justify-between items-center')
      | {{ sameDayShippingText }} Only

      div(class='flex-none ml-2 my-2 h-4 w-8 flex items-center rounded-full border-2 border-gray' :class='toggleColors')
        span(class='h-3 w-3 bg-white rounded-full transform shadow' :class='[!isActive ? "translate-x-0" : "translate-x-4"]')

  div(v-show='!isActive && !isDisabled' class='-mt-3 pl-10 pb-3 pr-3 text-sm')
    p Only display items that ship the quickest
</template>

<script setup lang="ts">
const emit = defineEmits(['onFitmentSelection', 'refinementClicked'])
const route = useRoute()
const { $algolia, $sitewideConfig } = useNuxtApp()
const fitmentDisplayStore = useFitmentDisplayStore()
const notifications = useNotifications()
const { sameDayShippingBackgroundColor, sameDayShippingText } = useSameDayShippingDisplay()

const resultCount = computed(() => {
  return $algolia.searchContexts.catalog.state.value.resultCount
})

const isDisabled = computed(() => {
  const refinements = $algolia.searchContexts.catalog.state.value.refinements.sameDayShipping || []

  return !refinements.some(({ value }) => value === 'true')
})

const isActive = computed(() => {
  const { sameDayShipping } = $algolia.searchContexts.catalog.state.value.activeRefinements

  return !!sameDayShipping
})

const toggleColors = computed(() => {
  if (isActive.value) {
    if ($sitewideConfig.config.sameDayShippingMode === 'RAPIDSHIP') return 'bg-success-dark'

    return 'bg-info-dark'
  }

  return 'bg-gray-dark'
})

const headerClasses = computed(() => {
  const classes = []

  if (isActive.value) {
    classes.push('text-white', sameDayShippingBackgroundColor)
  } else {
    classes.push('bg-white text-dark')
  }

  return classes
})

function toggleFacet() {
  if (isDisabled.value) {
    notifications.addInfo(
      `Oops, no ${sameDayShippingText} items available for that selection`,
      `Don't worry! Just choose a different brand, category, or price range to see ${sameDayShippingText} items!`
    )
    return
  }

  const { sameDayShipping } = $algolia.searchContexts.catalog.state.value.activeRefinements

  // If sameDayShipping exists in the activeRefinements then we are currently refined so we need to remove it.
  if (sameDayShipping) $algolia.searchContexts.catalog.clearRefinements('sameDayShipping')
  // Since we adding the facet if we get to this else if,
  // If the user doesn't have full fitment, then we need to open the fitment modal instead of applying the facet and pushing the route.
  else if (!fitmentDisplayStore.hasFullFitment) {
    emit('onFitmentSelection')
    fitmentDisplayStore.showFitmentModal({ isSameDayShippingMode: true })
    return
  }
  // Just add the facet because we are in a valid state to do so.
  else $algolia.searchContexts.catalog.addRefinements('sameDayShipping', 'true')

  const { mod } = route.query

  const newRoute: any = {
    name: route.name,
    params: {
      ...route.params,
    },
    query: {
      ...(mod && { mod }),
      ...$algolia.searchContexts.catalog.createRouteQuery(),
    },
  }

  emit('refinementClicked', {
    attribute: 'sameDayShipping',
    title: 'Shipping',
    value: !!sameDayShipping,
  })

  navigateTo(newRoute)
}
</script>
