<template lang="pug">
div
  template(v-if='$device.isSmall')
    Overlay(unique-key='sortMenu' title='Sort' :is-persistent='true')
      AlgoliaSortBy
        template(#sort='{ label, isActive }')
          div(
            class='before:w-5 before:h-5 before:rounded-[50%] before:border-2 before:border-solid before:inline-block before:mr-[15px] before:relative before:top-[3px] bg-white p-4 mb-4 cursor-pointer border-solid rounded text-lg text-left'
            :class='isActive ? "before:border-[7px] before:border-solid before:border-action border-2 border-action" : "border border-gray-light"'
          ) {{ label }}

      template(#footer)
        BaseButton(class='w-full' @click='$overlay.close()') Close

  template(v-else)
    div(class='flex items-center text-sm font-bold')
      span Sort By:&nbsp;
      AlgoliaSortBy
</template>

<script setup lang="ts">
const { $device, $overlay } = useNuxtApp()
</script>
