<template lang="pug">
BaseButton(
  size='SMALL'
  color='GRAY'
  button-style='OUTLINE'
  class='xs:justify-between'
  :class='[isDisabled ? "opacity-25 cursor-default" : "cursor-pointer"]'
  @click='toggleFacet'
)
  span(class='lg:text-sm') {{ sameDayShippingText }}

  div(class='flex-none ml-2 h-4 w-6 lg:w-8 flex items-center rounded-full border-2 border-transparent' :class='toggleColors')
    span(
      class='h-3 w-3 bg-white rounded-full transform shadow'
      :class='[!isActive ? "translate-x-0" : "translate-x-2 lg:translate-x-4"]'
    )
</template>

<script setup lang="ts">
const emit = defineEmits(['refinementClicked']) // LAME
const { isActive, isDisabled, toggleColors, toggleFacet } = useSameDayFacetHelper(emit)
const { sameDayShippingText } = useSameDayShippingDisplay()
</script>
