<template lang="pug">
ul(class='refinement-list bg-white text-sm lg:overflow-x-hidden lg:overflow-y-auto')
  li(
    v-for='category in props.data.values'
    :key='category.display'
    class='refinement-value m-0 h-14 block pl-10 text-sm text-black border-b-2 border-gray-lighter'
  )
    div(class='flex items-center h-14')
      nuxt-link(:to='category.route' class='flex items-center w-full text-dark h-14')
        span(@click='emit("onFilterMenuClose")') {{ category.display }}
</template>

<script setup lang="ts">
const emit = defineEmits(['onFilterMenuClose'])
const props = defineProps<{ data: any }>()
</script>
