<template lang="pug">
div(class='relative bg-white w-full h-screen flex flex-col overflow-x-hidden')
  div(class='h-screen max-h-screen')
    div(
      class='flex items-center justify-between pl-10 pr-3 h-12 text-black uppercase font-bold bg-gray-lighter border-b border-gray-light'
    ) Sort

    div(class='overflow-y-auto h-[calc(100vh-56px)] pb-36')
      template(v-for='({ label }, index) in sorts' :key='label')
        div(
          class='flex cursor-pointer m-0 h-14 w-full pl-10 text-sm text-black border-b-2 border-gray-lighter items-center'
          @click='activeSort = index'
        )
          div(
            class='before:w-5 before:h-5 before:rounded-[50%] before:border-2 before:border-solid before:inline-block before:mr-[15px] before:relative before:top-[3px] m-0'
            :class='index === activeSort ? "before:border-[7px] before:border-action" : "before:border-gray-light"'
          ) {{ label }}
</template>

<script setup lang="ts">
const { $algolia } = useNuxtApp()
const route = useRoute()
const sorts = computed(() => $algolia.searchContexts.catalog.state.value.sorts)
const activeSort = computed({
  get() {
    return $algolia.searchContexts.catalog.state.value.currentSort
  },
  set(index) {
    updateSort(index)
  },
})

function updateSort(index: string) {
  $algolia.searchContexts.catalog.setSort(index)

  const { mod } = route.query

  const newRoute: any = {
    name: route.name,
    params: {
      ...route.params,
    },
    query: {
      ...(mod && { mod }),
      ...$algolia.searchContexts.catalog.createRouteQuery(),
    },
  }

  navigateTo(newRoute)
}
</script>
