<template lang="pug">
div(
  class='flex flex-wrap justify-between items-center sm:mb-6'
  :class='{ "mb-6": isCategoryFilterAB || !catalogData.showSameDayShippingFacet }'
)
  //- A/B Category filter test - show old toolbar
  div(
    v-if='!isCategoryFilterAB'
    class='flex justify-between items-center gap-2'
    :class='{ "sm:w-auto": catalogData.showSameDayShippingFacet, "w-full": $device.isSmall }'
  )
    div(v-if='$device.isSmall && catalogData.showSameDayShippingFacet' class='flex-initial')
      CatalogRefinementsSameDayShippingButton

    div(v-if='$device.isSmall && !catalogData.showSameDayShippingFacet' class='flex-initial mr-1 grow')
      AlgoliaStats(class='font-bold')

    div(
      v-if='$device.isSmall && !$sitewideConfig.config.nonTransactionalEnabled'
      class='flex-auto'
      :class='{ "grow-0": !catalogData.showSameDayShippingFacet }'
    )
      BaseButton(size='SMALL' color='GRAY' button-style='OUTLINE' class='w-full' @click='$overlay.open("sortMenu")')
        img(src='/images/sort.svg' alt='sort' class='h-2 w-2 mr-2' loading='lazy')
        | Sort

    div(v-if='$device.isSmall' class='flex-auto' :class='{ "grow-0": !catalogData.showSameDayShippingFacet }')
      BaseButton(
        size='SMALL'
        color='GRAY'
        button-style='OUTLINE'
        has-custom-padding
        class='w-full px-4 py-3 sm:px-6'
        @click='$overlay.open("facetMenu")'
      )
        span(class='w-[18px] mr-1 -ml-1 justify-end inline-flex')
          img(v-if='activeRefinementCount === 0' src='/images/filter.svg' alt='filter' class='h-2 w-2' loading='lazy')
          span(
            v-else
            class='w-[18px] h-[18px] leading-none rounded-full items-center justify-center inline-flex bg-gray-light'
          ) {{ activeRefinementCount }}
        span Filter

  //- A/B Category filter test - show new toolbar on desktop & mobile
  //- ---
  //- Was easier to duplicate the toolbar instead of doing v-if checks on every button
  //- for both desktop & mobile
  div(v-else class='flex justify-start items-center gap-2 sm:w-auto' :class='{ "w-full": $device.isSmall }')
    div(v-if='catalogData.showSameDayShippingFacet' class='hidden md:block lg:flex-initial')
      CatalogRefinementsSameDayShippingButton(class='text-sm font-normal')

    div(v-if='!$sitewideConfig.config.nonTransactionalEnabled' class='flex-auto')
      BaseButton(
        size='SMALL'
        color='GRAY_DARKEST'
        button-style='OUTLINE'
        class='w-full capitalize'
        @click='openFilterSortMenu'
      )
        img(src='/images/sort.svg' alt='sort' class='h-3 w-3 mr-2' loading='lazy')
        span(class='font-normal text-sm') Sort

    div(class='flex-auto')
      BaseButton(size='SMALL' color='GRAY_DARKEST' button-style='OUTLINE' class='w-full capitalize' @click='openFilterMenu')
        img(v-if='activeRefinementCount === 0' src='/images/filter.svg' alt='filter' class='h-3 w-3 mr-2' loading='lazy')
        span(
          v-else
          class='mr-2 w-[18px] h-[18px] text-sm leading-none rounded-full items-center justify-center inline-flex bg-gray-light'
        ) {{ activeRefinementCount }}
        span(class='font-normal text-sm') All Filters

    //- Might be a better way to check if we should display the Category button here
    div(v-if='!$device.isSmall && categoryGroup.values.length > 0' class='flex-auto')
      BaseButton(
        size='SMALL'
        color='GRAY_DARKEST'
        button-style='OUTLINE'
        class='w-full capitalize'
        @click='openFilterSubMenu(categoryGroup.title)'
      )
        span(class='font-normal text-sm') Category

    template(v-for='facet in catalogData.facets' :key='facet.text')
      div(v-if='!$device.isSmall && refinements(facet.facet).length > 0' class='flex-auto')
        BaseButton(
          size='SMALL'
          color='GRAY_DARKEST'
          button-style='OUTLINE'
          class='w-full capitalize'
          :class='facet.facet in activeRefinements ? "border-2" : ""'
          @click='openFilterSubMenu(facet.text)'
        )
          span(class='text-sm' :class='facet.facet in activeRefinements ? "font-bold" : "font-normal"') {{ facet.text }}

  //- A/B Category filter update - do not show search stats in test
  div(
    v-if='!$device.isSmall && !catalogData.showSameDayShippingFacet && !isCategoryFilterAB'
    class='py-4 w-full sm:w-auto sm:order-first sm:py-0 sm:mr-auto'
  )
    AlgoliaStats(class='font-bold w-full text-center')

  div(
    v-if='catalogData.showSameDayShippingFacet && !isCategoryFilterAB'
    class='py-4 w-full sm:w-auto sm:order-first sm:py-0 sm:mr-auto'
  )
    AlgoliaStats(class='font-bold w-full text-center')

  //- A/B Category filter update - do not show sort overlay in test
  CatalogSortBy(v-if='!isCategoryFilterAB && !$sitewideConfig.config.nonTransactionalEnabled')

  CatalogFilterSlideMenu(
    v-if='isCategoryFilterAB'
    :show-filter-menu='showFilterMenu'
    :filter-sub-menu='filterSubMenu'
    :menu-type='menuType'
    :catalog-data='catalogData'
    @on-toggle='toggleFilterMenu'
    @close-filter-sub-menu='closeFilterSubMenu'
  )
</template>

<script setup lang="ts">
const { $device, $algolia } = useNuxtApp()
const showFilterMenu = ref(false)
const filterSubMenu = ref()
const menuType = ref('filter')
const { catalogData, isCategoryFilterAB = false } = defineProps<{
  catalogData: any
  isCategoryFilterAB: boolean
}>()

const { categoryGroup } = useFilterSlideMenuHelper(catalogData)
const activeRefinementCount = computed(() => {
  return $algolia.searchContexts.catalog.state.value.activeRefinementCount
})
const activeRefinements = computed(() => {
  return $algolia.searchContexts.catalog.state.value.activeRefinements
})

function openFilterSubMenu(menu: any) {
  filterSubMenu.value = menu
  openFilterMenu()
}

function toggleFilterMenu() {
  showFilterMenu.value = !showFilterMenu.value
}

function openFilterSortMenu() {
  menuType.value = 'sort'
  toggleFilterMenu()
}

function openFilterMenu() {
  menuType.value = 'filter'
  toggleFilterMenu()
}

function closeFilterSubMenu() {
  filterSubMenu.value = null
}

function refinements(facet: any) {
  const refinements = $algolia.searchContexts.catalog.state.value.refinements[facet] || []
  return refinements.filter(({ isValid }) => isValid)
}
</script>
