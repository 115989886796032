<template lang="pug">
div
  template(v-if='$slots.sort')
    template(v-for='({ label }, index) in sorts' :key='label')
      button(class='block w-full' @click='activeSort = index')
        slot(name='sort' :label='label' :is-active='index === activeSort')

  template(v-else)
    div(class='select is-small')
      select(v-model='activeSort')
        template(v-for='({ label }, index) in sorts' :key='label')
          option(:value='index') {{ label }}
</template>

<script>
import SearchComponent from './Component'

export default defineNuxtComponent({
  extends: SearchComponent,
  computed: {
    sorts() {
      return this.$searchContext.state.value.sorts
    },
    activeSort: {
      get() {
        return this.$searchContext.state.value.currentSort
      },
      set(index) {
        this.updateSort(index)
      },
    },
  },
  setup() {
    return {
      navigateTo,
    }
  },
  methods: {
    updateSort(index) {
      // If you navigateTo the same routeObj we lose the / at the end of the url
      // which kills overlay isPersistant. So we are early outing instead.
      if (this.activeSort === index) return

      this.$searchContext.setSort(index)

      const { mod } = this.$route.query

      const routeObj = {
        name: this.$route.name,
        params: {
          ...this.$route.params,
        },
        query: {
          ...(mod && { mod }),
          ...this.$searchContext.createRouteQuery(),
        },
      }

      this.navigateTo(routeObj)
    },
  },
})
</script>
