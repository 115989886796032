<template lang="pug">
div(class='relative bg-white w-full h-screen flex flex-col overflow-x-hidden')
  div(class='h-screen max-h-screen')
    div(
      class='flex items-center justify-between pl-10 pr-3 h-12 text-black uppercase font-bold bg-gray-lighter border-b border-gray-light'
    ) Filter

    div(class='overflow-y-auto h-[calc(100vh-56px)] pb-36')
      CatalogFilterSlideMenuSameDayShipping(
        v-if='catalogData.showSameDayShippingFacet'
        class='border-b-2 border-gray-lighter'
        @on-fitment-selection='closeFilterMenu'
      )

      ul
        //- Display the category filter if sub categories exist
        li(v-if='categoryGroup.values.length > 0' class='block pl-10 text-sm text-black border-b-2 border-gray-lighter')
          CatalogFilterSlideMenuSubMenu(
            ref='subMenu'
            :data='categoryGroup'
            :filter-sub-menu='filterSubMenu'
            @on-filter-sub-menu-close='closeSubMenu'
          )
            template(#title) {{ categoryGroup.title }}
            template(#content)
              CatalogFilterSlideMenuCategoryList(:data='categoryGroup' @on-filter-menu-close='closeFilterMenu')

        template(v-for='facet in catalogData.facets' :key='facet.text')
          li(v-if='refinements(facet.facet).length > 0' class='block pl-10 text-sm text-black border-b-2 border-gray-lighter')
            CatalogFilterSlideMenuSubMenu(
              ref='subMenu'
              :data='facet'
              :filter-sub-menu='filterSubMenu'
              @on-filter-sub-menu-close='closeSubMenu'
            )
              template(#title) {{ facet.text }}
              template(#content)
                CatalogFilterSlideMenuFilterList(:data='facet')
</template>

<script setup lang="ts">
const { $algolia } = useNuxtApp()
const emit = defineEmits(['closeFilterSubMenu', 'closeFilterMenu'])
const subMenu = ref() // template ref -- array?!
const { filterSubMenu, catalogData } = defineProps<{
  filterSubMenu?: string // // String name of the sub menu that is currently open; taken from the catalog data facet.text, Possible values 'Category' or a facet.text value
  catalogData: any
}>()
const { categoryGroup } = useFilterSlideMenuHelper(catalogData)

defineExpose({
  closeFilterSubMenu,
})

function refinements(facet) {
  const refinements = $algolia.searchContexts.catalog.state.value.refinements[facet] || []
  return refinements.filter(({ isValid }) => isValid)
}

function closeFilterSubMenu() {
  // Close each sub menu
  subMenu.value.forEach((ref) => {
    ref.closeMenu()
  })
  closeSubMenu()
}

function closeSubMenu() {
  emit('closeFilterSubMenu')
}

function closeFilterMenu() {
  emit('closeFilterMenu')
}
</script>
