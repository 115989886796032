export default function (catalogData: any) {
  const { $algolia } = useNuxtApp()
  const routeBuilder = useRouteBuilder()

  const level = computed(() => {
    return catalogData.category.facetLevel
  })

  // Taken from the refinementCategory component
  const categoryGroup = computed(() => {
    const refinementNames = $algolia.searchContexts.catalog.state.value.refinements[`categories.lvl${level}.text`] || []
    const refinementSlugs = $algolia.searchContexts.catalog.state.value.refinements[`categories.lvl${level}.slug`] || []

    const refinements = refinementNames.map((refinement, index) => {
      const slug = refinementSlugs[index].value.split('|')

      const newRoute = {
        name: 'category',
        params: slug.reduce((acc, lvl, lvlIndex) => {
          acc[`lvl${lvlIndex}`] = lvl
          return acc
        }, {}),
        query: {},
      }

      return {
        ...refinement,
        slug,
        route: routeBuilder.formatRoute(newRoute),
      }
    })

    const values = refinements.filter((refinement) => refinement.slug.join('/').indexOf(catalogData.slug) === 0)

    const categoryGroup = {
      title: 'Category',
      values,
    }

    return categoryGroup
  })

  return {
    categoryGroup,
  }
}
