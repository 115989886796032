export default function (emit: ReturnType<typeof defineEmits>) {
  const { $algolia, $sitewideConfig } = useNuxtApp()
  const route = useRoute()
  const fitmentDisplayStore = useFitmentDisplayStore()
  const notifications = useNotifications()
  const { sameDayShippingText } = useSameDayShippingDisplay()
  const isActive = computed(() => {
    return !!$algolia.searchContexts.catalog.state.value.activeRefinements.sameDayShipping
  })

  const isDisabled = computed(() => {
    const refinements = $algolia.searchContexts.catalog.state.value.refinements.sameDayShipping || []
    return !refinements.some(({ value }) => value === 'true')
  })

  const toggleColors = computed(() => {
    if (isActive.value) {
      if ($sitewideConfig.config.sameDayShippingMode === 'RAPIDSHIP') return 'bg-success-dark'

      return 'bg-info-dark'
    }
    return 'bg-gray-dark'
  })

  function toggleFacet() {
    if (isDisabled.value) {
      notifications.addInfo(
        `Oops, no ${sameDayShippingText} items available for that selection`,
        `Don't worry! Just choose a different brand, category, or price range to see ${sameDayShippingText} items!`
      )
      return
    }

    const { sameDayShipping } = $algolia.searchContexts.catalog.state.value.activeRefinements

    // If sameDayShipping exists in the activeRefinements then we are currently refined so we need to remove it.
    if (sameDayShipping) $algolia.searchContexts.catalog.clearRefinements('sameDayShipping')
    // Since we adding the facet if we get to this else if,
    // If the user doesn't have full fitment, then we need to open the fitment modal instead of applying the facet and pushing the route.
    else if (!fitmentDisplayStore.hasFullFitment) {
      fitmentDisplayStore.showFitmentModal({ isSameDayShippingMode: true })
      return
    }
    // Just add the facet because we are in a valid state to do so.
    else {
      $algolia.searchContexts.catalog.addRefinements('sameDayShipping', 'true')
    }

    const { mod } = route.query

    const routeObj: any = {
      name: route.name,
      params: {
        ...route.params,
        // noUpdateScroll: true,
      },
      query: {
        ...(mod && { mod }),
        ...$algolia.searchContexts.catalog.createRouteQuery(),
      },
    }

    emit('refinementClicked', {
      attribute: 'sameDayShipping',
      title: 'Shipping',
      value: !!sameDayShipping,
    })

    navigateTo(routeObj)
  }

  return {
    isActive,
    isDisabled,
    toggleColors,
    toggleFacet,
  }
}
